import { graphql } from "gatsby"
import HomepageComponent from "../components/homepage"

export default HomepageComponent

export const query = graphql`
  query {
    pages: allPage(sort: { fields: title, order: ASC }, limit: 3) {
      nodes {
        slug
        title
        cover {
          childImageSharp {
            gatsbyImageData(width: 1000, quality: 85, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        __typename
      }
    }
    projects: allProject(sort: { fields: date, order: DESC }, limit: 10) {
      nodes {
        slug
        title: shortTitle
        cover {
          childImageSharp {
            gatsbyImageData(width: 1000, quality: 85, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        __typename
      }
    }
  }
`
